import { useState } from "react";
import Modal from '../../components/Ui/Modal';

function EmailForm(props) {
  const [email, setEmail] = useState()
  const [error, setError] = useState()

  const handleSubmit = (e) => {
    e.preventDefault()

    if (email && email !== '') {
      props.emailAdded();
      fetch('/contacts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email})
      });
    } else {
      setError('Please enter your email address')
    }
  }

  return(
    <Modal
      active={true}
    >
      <form onSubmit={handleSubmit}>
        <div onClick={props.close} className="text--link" role="button">
          X CLOSE
        </div>
        <p>
          Add your email to enter:
        </p>

        <div className="input-row">
          <input
            type='email'
            name='email'
            onChange={e => setEmail(e.target.value)}
            placeholder='Email address...'
            autoComplete="off"
          />
        </div>

        <p>
          {error}
        </p>

        <div className="input-row">
          <button type='submit' className='btn--primary btn--paint'>ADD EMAIL</button>
        </div>
      </form>
    </Modal>
  )
}

export default EmailForm;