import classes from './Pins.module.css';

function Cta(props) {
  return (
    <div onClick={props.openForm}  className={classes.wrapper} id='pin-cta'>
      <div className={classes.label}>
        DROP A PIN TO SHARE WHICH EDITION YOU PICKED UP & WHERE
      </div>
      <button className='btn btn--circle btn--circle--md btn--primary'>
        +
      </button>
    </div>
  )
}

export default Cta;