import classes from './MenuPanel.module.css';
import IconSpotify from '../Icons/IconSpotify'
import IconApple from '../Icons/IconApple'
import IconTidal from '../Icons/IconTidal'
import IconYouTube from '../Icons/IconYouTube'
import IconFacebook from '../Icons/IconFacebook'
import IconInstagram from '../Icons/IconInstagram'
import IconTwitter from '../Icons/IconTwitter'
import IconTikTock from '../Icons/IconTikTok'
import { useEffect, useState } from 'react';

function MenuPanel(props) {
  return (
    <div className={classes.wrapper} data-active={props.active}>
    
      <div className={classes.content}>
        <div className={classes.nav}>
          <div className={classes.navLink}><a href='https://a7xworld.com/' target='_blank' rel='noreferrer'>Merch</a></div>
          <div className={classes.navLink}><a href='https://www.avengedsevenfold.com/tour' target='_blank' rel='noreferrer'>Tour</a></div>
  
          <div className={classes.navLink}>
            Follow
            <ul className={classes.socials}>
              <li><a href='https://open.spotify.com/artist/0nmQIMXWTXfhgOBdNzhGOs' target='_blank' rel='noreferrer'><IconSpotify /></a></li>
              <li><a href='https://itunes.apple.com/us/artist/avenged-sevenfold/id6766228?uo=4&at=10lrBM' target='_blank' rel='noreferrer'><IconApple /></a></li>
              <li><a href='https://listen.tidal.com/artist/14155' target='_blank' rel='noreferrer'><IconTidal /></a></li>
              <li><a href='https://www.instagram.com/avengedsevenfold/' target='_blank' rel='noreferrer'><IconInstagram /></a></li>
              <li><a href='https://www.facebook.com/AvengedSevenfold' target='_blank' rel='noreferrer'><IconFacebook /></a></li>
              <li><a href='https://twitter.com/TheOfficialA7X' target='_blank' rel='noreferrer'><IconTwitter /></a></li>
            </ul>
          </div>
        </div>
        
        <div  className={classes.album}>
          <a href='' target='_blank' rel='noreferrer'>
            <div className={classes.albumArt}>
              <img src='https://d1nbo7haysbnoj.cloudfront.net/images/A7X_LifeIsButaDream-LPCover.jpg' alt='album art' />
            </div>
          </a>
          <span>Stream/Buy</span>
        </div>
      </div>  
    </div>
  )
}

export default MenuPanel;