import { useEffect, useState } from "react"
import classes from './Admin.module.css';

function Admin() {
  const [passwordValid, setPasswordValid] = useState(false)
  const [password, setPassword] = useState()
  const [error, setError] = useState()
  const [pins, setPins] = useState([]);
 
  useEffect(() => {
    if (password && passwordValid) {
      getPins();
    }
  }, [password, passwordValid])

  const getPins = async () => {
    const request = await fetch('/pending-pins', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Password ${password}`
      }
    })
    .then(response => response.json())
    .then(data => {
      setPins(data)
      return data
    })
    .catch(error => {
      return error
    })
  }

  const approvePin = (e) => {
    fetch(`/pins/${e.target.dataset.pinid}`, {
      method: 'PUT',
      headers: {
       'Content-Type': 'applicaition/json',
       'Authorization': `Password ${password}`
      }
    })
    .then(response => response.json())
    .then(data => {
      getPins();
    })
    .catch(error => console.log(error))
  }

  const deletePin = (e) => {
    fetch(`/pins/${e.target.dataset.pinid}`, {
      method: 'DELETE',
      headers: {
       'Content-Type': 'applicaition/json',
       'Authorization': `Password ${password}`
      }
    })
    .then(response => response.json())
    .then(data => {
      getPins();
    })
    .catch(error => console.log(error))
  }

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch('/check-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({password})
    })
    
    if (!response.ok) {
      return setError('Invalid password')
    }

    const data = await response.json();

    if (data.error) {
      return setError('Invalid password');
    }

    setPasswordValid(true);
  }

  return(
    <div className={classes.wrapper}>
      <div className={classes.content}> 
        {passwordValid && pins
          ? 
            <>
              <h1>Manage Fan Pins</h1>
              
              <div>
                <p>
                  Pins Pending Approval: {pins.length}
                </p>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>DATE ADDED</th>
                      <th>ID</th>
                      <th>NAME</th>
                      <th>STORE</th>
                      <th>IMAGE</th>
                      <th>STATUS</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {pins.map((pin, i) => (
                      <tr key={`pin_${i}`}>
                        <td>{pin.createdAt}</td>
                        <td>{pin._id}</td>
                        <td>{pin.name}</td>
                        <td>{pin.location.name}</td>
                        <td>
                          <div className={classes.tableImgWrap}>
                          <img src={pin.image_url} />
                          </div>
                        </td>
                        <td>{!pin.approved ? 'Pending' : ''}</td>
                        <td>
                          <button onClick={approvePin} data-pinid={pin._id}>APPROVE</button>
                          <button onClick={deletePin} data-pinid={pin._id}>DELETE</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          : 
            <form onSubmit={handlePasswordSubmit}>
              <p>
                Enter your password:
              </p>
              <div>
                <input
                  type='text'
                  name='password'
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div>
                <p>{error}</p>
                <button type='submit'>SUBMIT</button>
              </div>
            </form>
        }
      </div>
    </div>
  )
}

export default Admin;