import { useEffect, useRef, useState } from "react";
import classes from './Scene.module.css';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import Map from "./Map";

let scene, camera, renderer, rendererCSS, raycaster, pointer, modelLoader;

let store, cssScreen, screenMesh;

let recordPlaying;

function Index(props) {
  const canvasWrapper = useRef();
  const cssWrapper = useRef();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [size, setSize] = useState();

  useEffect(() => {
    initGraphics();
  }, []);

  const initGraphics = () => {
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);

    // if (window.innerWidth < 800) {
    //   camera.position.z = 6;
    //   camera.position.y = 3;
    // } else {
    //   camera.position.z = 3.5;
    //   camera.position.y = 2.5;
    // }

    camera.position.z = 6;
      camera.position.y = 3;

    renderer = new THREE.WebGLRenderer({alpha: true, antialias: true})
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.shadowMap.enabled = true;
    canvasWrapper.current.appendChild(renderer.domElement);

    // CSS RENDERER
    // rendererCSS = new CSS3DRenderer();
    // rendererCSS.setSize( window.innerWidth, window.innerHeight );
    // cssWrapper.current.appendChild( rendererCSS.domElement );

    
    window.addEventListener('resize', resize, false);

    // LIGHTS
    const ambLight = new THREE.AmbientLight(0xFFFFFF, 0.6);
    scene.add(ambLight);

    const dirLight = new THREE.DirectionalLight(0xFFFFFF, 0.8);
    dirLight.position.set(7.5, 3, 5);
    dirLight.target.position.set(7, 3, 0);
    dirLight.castShadow = true;
    dirLight.shadow.bias = -0.005;
    dirLight.shadow.camera.top = 6;
    dirLight.shadow.camera.bottom = -6;
    dirLight.shadow.camera.left = -6;
    dirLight.shadow.camera.right = 6;
    dirLight.shadow.camera.near = 0.1;
    dirLight.shadow.camera.far = 20;
    scene.add(dirLight);
    scene.add(dirLight.target);
    
    // MODELS


    let modelUrl = 'https://d1nbo7haysbnoj.cloudfront.net/models/a7x-record-store_record-stand_v1.glb';
    modelUrl = './models/a7x-record-store_no-record-stand.glb'
    modelLoader = new GLTFLoader();
    modelLoader.load(modelUrl, gltf => {
      store = gltf.scene;

      store.traverse(node => {
        if ( node.isMesh ) { 
          node.castShadow = true;
          node.receiveShadow = true;

          if (!node.name.includes('center-rack')) {
            node.visible = false;
          }
        }
        
      });

      recordPlaying = store.getObjectByName('record-now-playing');
      
      scene.add(store);
      
      setModelsLoaded(true);
      props.handleModelsLoaded();
    });


    // addMap();
    
    
    animate();
  }

  const addMap = () => {
    const obj = new THREE.Object3D

    const div = document.createElement( 'div' );
    const divMap = document.querySelector( '#map-container' );
    
    if (window.innerWidth < 800 && !props.debugMode) {
      div.style.width = '600px';
      div.style.height = '1000px';
    } else {
      div.style.width = '600px';
      div.style.height = '600px';
    }
    
    div.style.opacity = '1';
    div.id = 'map-screen';

    div.appendChild(divMap)

    cssScreen = new CSS3DObject( div );
    cssScreen.scale.set(0.005, 0.005, 0.005);
    cssScreen.rotation.y = 0;

    obj.add( cssScreen );

    // make an invisible plane for the DOM element to chop
    // clip a WebGL geometry with it.
    var material = new THREE.MeshPhongMaterial({
      opacity	: 0.15,
      blending: THREE.AdditiveBlending,
      color: 0x000000
      // side	: THREE.DoubleSide,
      // map: textureLoader.load('/models/textures/map-texture-overlay-5.png')
    });
    if (window.innerWidth < 800 && !props.debugMode) {
      var geometry = new THREE.BoxGeometry( 3, 5, 0.001 );
    } else {
      var geometry = new THREE.BoxGeometry( 3, 3, 0.0001 );
    }
    screenMesh = new THREE.Mesh( geometry, material );
    screenMesh.castShadow = true;
    screenMesh.receiveShadow = true;
    screenMesh.visible = true;
    obj.lightShadowMesh = screenMesh
    obj.add( screenMesh );

    if (window.innerWidth < 800 && !props.debugMode) {
      obj.position.set(0,3.2,0.55)
    } else {
      obj.position.set(0,2.7,0.55)
    }
    scene.add(obj)
    resize()
  }

  const animate = () => {
    requestAnimationFrame(animate);

    if (recordPlaying) {
      recordPlaying.rotation.y -= 0.04;
    }

    // rendererCSS.render( scene, camera );
    renderer.render(scene, camera);
  }

  const resize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight)
    // rendererCSS.setSize(window.innerWidth, window.innerHeight);
  }

  const handleMapLoaded = () => {
    console.log('map loaded');
    setMapLoaded(true);
  }

  return (
    <> 
      {/* <div ref={cssWrapper} className={classes.webGlWrapper}></div> */}
      <div ref={canvasWrapper} className={classes.webGlWrapper} data-pointer={false}></div>
    </>
  )
}

export default Index;