import { useState } from 'react';
import styles from './Sharing.module.css';
import IconTwitter from '../Icons/IconTwitter';
import IconFacebook from '../Icons/IconFacebook';

function Sharing(props) {
  const [sharingActive, setSharingActive] = useState(false);
  
  const showSharing = () => {
    setSharingActive(true)
  }
  const hideSharing = () => {
    setSharingActive(false)
  }

  const fbShare = (e) => {
		var url = e.target.dataset.url;
		document.dispatchEvent(new CustomEvent('fbShare', {detail: {url: url} }))
	}

  return (
    <div className={styles.sharingContent}>
      <button onClick={fbShare} className={styles.btnFacebook} data-url='https://map.avengedsevenfold.com/'><IconFacebook /> share</button>
      <a href="https://twitter.com/intent/tweet?text=Explore the Avenged Sevenfold record store and find 'Life is But a Dream...' near you!&url=https://map.avengedsevenfold.com/&hashtags=LifeIsButADream&via=TheOfficialA7X" className={styles.btnTwitter}><IconTwitter /> share</a>
    </div>
  )
}

export default Sharing;