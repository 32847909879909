import Modal from "../Ui/Modal";

function Instructions(props) {
  return(
    <Modal
      active={true}
      close={props.closeInstructions}
      size='small'
      allowClose={true}
    >
      <p className="text--lead">
        Enter your address to find your copy of 'Life is But a Dream…' at your favorite record store or retailer. Search by store, city, or zip.
      </p>
      <br/>
      <button className='btn btn--primary btn--block btn--paint' onClick={props.closeInstructions}>GOT IT</button>
    </Modal>
  )
}

export default Instructions;