import { useState } from "react";
import classes from './Welcome.module.css';
import LoadingBar from '../../components/Ui/LoadingBar';
import EmailForm from "./EmailForm";
import LogoA7x from "../Ui/Logos/LogoA7x";

function Welcome(props) {
  const [emailFormActive, setEmailFormActive] = useState(false);

  const openEmailForm = () => {
    setEmailFormActive(true)
  }

  const closeEmailForm = () => {
    setEmailFormActive(false)
  }

  const emailAdded = () => {
    setEmailFormActive(false)
    props.confirmWelcome();
  }

  return(
    <div className='screen' data-role='welcome'>
      <div className='screen__overlay'></div>
      <div className={classes.content}>
        <div className={classes.welcome}>WELCOME TO THE</div>
        <div className={classes.logo}>
          <img src='https://d1nbo7haysbnoj.cloudfront.net/images/A7X_LifeIsButaDream-text_offwhite.png' />
        </div>
        <h1 className={classes.title}>VINYL LOCATOR</h1>

        {props.loading && <LoadingBar />}

        <div>
          {props.audioReady
            ? <button onClick={props.confirmWelcome} className='btn--primary btn--paint'>LET'S GO</button>
            : <>
                <div className="patchbay-patch" data-id="646e0e6101e1a8bc4e928cf2"></div>
                {!props.loading && <button onClick={openEmailForm} className='btn--primary btn--paint'>Or Enter  With Email</button>}
              </>
          }
        </div>
      </div>
      
      { emailFormActive && <EmailForm emailAdded={emailAdded} close={closeEmailForm} /> }
    </div>
  )
}

export default Welcome;