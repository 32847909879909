import React from 'react'
import styles from './Location.module.css'
import IconDirections from '../../components/Ui/Icons/Directions'

function Location(props) {
  return (
    <div className={styles.wrapper} data-active={props.markerActive}>
      <div className={styles.content}>
        <div onClick={props.closeMarker} className={styles.close}>X CLOSE</div>
        <div className={styles.scrollWrap}>
          <div className={styles.addressWrapper}>
            <div className={styles.address}>
              {props.location.external_id ? 'Hot Topic' : props.location.name}<br/>
              {props.location.address}<br/>
              {props.location.address_2
                ? <div>{props.location.address_2}<br/></div>
                : null
              }
              {props.location.city}, {props.location.state} {props.location.zip}
            </div>  
            <a href={`https://www.google.com/maps/dir//${props.location.name}+${props.location.address}+${props.location.city}+${props.location.state}+${props.location.zip}`} className={styles.directions} target='_blank' rel='noreferrer'>
              <IconDirections />
              <div>
                Directions
              </div>
            </a>
          </div>

          <div className={styles.section} data-role='vinyl'>
            {props.location.category === 'world-exclusive' 
              ?
              <>
                <h2>A7X World Exclusive Vinyl</h2>
                <p>
                  On the day of the show, visit the merch booth outside the venue and pick up the Forum/Madison Square Garden exclusive vinyl. Ticket to the show is not required for purchase.
                </p>
                <small><em>Exclusive vinyl available while supplies last.</em></small>
              </>
                
              :<h2>Limited Editions</h2>
            }


            
            <div className={styles.vinylWrapper}>

              {props.location.external_id && (
                <div className={styles.vinylImgWrapper}>
                  <img src='https://d1nbo7haysbnoj.cloudfront.net/images/records/vinyl-hot-topic_v1.png' />
                </div>
              )}
              
              {(props.location.category === 'guitar-center' || props.location.category === 'fye') && (
                <div className={styles.vinylImgWrapper}>
                  <img src='https://d1nbo7haysbnoj.cloudfront.net/images/records/vinyl-guitar-center_v1.png' />
                </div>
              )}

              {props.location.category === 'barnes-noble' && (
                <div className={styles.vinylImgWrapper}>
                  <img src='https://d1nbo7haysbnoj.cloudfront.net/images/records/vinyl-barnes-noble_v1.png' />
                </div>
              )}

              {props.location.category === 'world-exclusive' && (
                <div className={styles.vinylImgWrapper}>
                  <img src='https://d1nbo7haysbnoj.cloudfront.net/images/records/vinyl-world-exclusive_v1.png' />
                </div>
              )}

              {props.location.category === 'zia-records' && (
                <>
                  <div className={styles.vinylImgWrapper}>
                    <img src='https://d1nbo7haysbnoj.cloudfront.net/images/records/vinyl-zia_v1.png' />
                  </div>
                  <div className={styles.vinylImgWrapper}>
                    <img src='https://d1nbo7haysbnoj.cloudfront.net/images/records/vinyl-indie_v1.png' />
                  </div>
                </>
              )}

              {props.location.category === 'Newbury Comics' && (
                <div className={styles.vinylImgWrapper}>
                  <img src='https://d1nbo7haysbnoj.cloudfront.net/images/records/vinyl-newbury_v1.png' />
                </div>
              )}

              {props.location.category === 'standard' && (
                <div className={styles.vinylImgWrapper}>
                  <img src='https://d1nbo7haysbnoj.cloudfront.net/images/records/vinyl-standard_v1.png' />
                </div>
              )}

              {props.location.category === 'standard-orange' && (
                <>
                  <div className={styles.vinylImgWrapper}>
                    <img src='https://d1nbo7haysbnoj.cloudfront.net/images/records/vinyl-standard_v1.png' />
                  </div>
                  <div className={styles.vinylImgWrapper}>
                    <img src='https://d1nbo7haysbnoj.cloudfront.net/images/records/vinyl-orange_v1.png' />
                  </div>
                </>
              )}

              {props.location.category === 'orange' && (
                <div className={styles.vinylImgWrapper}>
                  <img src='https://d1nbo7haysbnoj.cloudfront.net/images/records/vinyl-orange_v1.png' />
                </div>
              )}

              {props.location.category === 'germany' && (
                <div className={styles.vinylImgWrapper}>
                  <img src='https://d1nbo7haysbnoj.cloudfront.net/images/records/A7X_LIBAD-DeathbatsClubLP_crop.png' />
                </div>
              )}

              {props.location.category === 'australia' && (
                <div className={styles.vinylImgWrapper}>
                  <img src='https://d1nbo7haysbnoj.cloudfront.net/images/records/cd_v1.png' />
                </div>
              )}

              {props.location.category === 'indie' && (
                <div className={styles.vinylImgWrapper}>
                  <img src='https://d1nbo7haysbnoj.cloudfront.net/images/records/vinyl-indie_v1.png' />
                </div>
              )}
              
            </div>
            
            {props.location.category !== 'world-exclusive' && <small><em>Please call the store for more information and album availability.</em></small>}
          </div>
          
          <div className={styles.section} data-role='pin-feed'>
            <h2>Fan Feed</h2>
            <div className={styles.pinFeed}>
              {props.location.pins
                ? props.location.pins.map((pin, i) => (
                    <div key={`pin_${i}`} className={styles.pinWrapper}>
                      <div className={styles.pinAspect}>
                        <div key={`pin_${i}`} className={styles.pin}>
                          <img src={pin.image_url}  alt='fan pin' />
                        </div>
                      </div>
                    </div>
                  ))
                : null
              }
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Location;