import { useEffect, useRef } from 'react';
import classes from './Static.module.css';

let canDrag = false;
let dragging = false;
let startX = 0;
let elementX = 0;
let maxX = 0;

function Static() {
  const scrollWrapRef = useRef();

  useEffect(() => {
    function initDrag(e) {
      if (e.target.dataset.role === 'art-wall') {
        console.log('as')
        canDrag = true
        startX = e.clientX ? e.clientX : e.touches[0].clientX
        elementX = scrollWrapRef.current.getBoundingClientRect().left
        maxX = -scrollWrapRef.current.getBoundingClientRect().width + window.innerWidth
      }
    }
    function drag(e) {
      if (canDrag) {
        scrollWrapRef.current.style.cursor = 'grabbing'
        const currentX = e.clientX ? e.clientX : e.touches[0].clientX
        const distance = currentX - startX
        const newX = distance + elementX


        if (Math.abs(distance) > 3) {
          dragging = true
          
          if (newX <= 0 && newX >= maxX) {
            scrollWrapRef.current.style.left = `${newX}px`
          }
        }
      }
    }
    function endDrag(e) {
      setTimeout(() => {
        dragging = false
      }, 100);
      canDrag = false
      scrollWrapRef.current.style.cursor = 'grab'
    }

    window.addEventListener('mousedown', initDrag)
    window.addEventListener('touchstart', initDrag)
    window.addEventListener('mousemove', drag)
    window.addEventListener('touchmove', drag)
    window.addEventListener('mouseup', endDrag)
    window.addEventListener('touchend', endDrag)
    return () => {
      window.removeEventListener("mousedown", initDrag)
      window.removeEventListener('touchstart', initDrag)
      window.removeEventListener("mousemove", drag)
      window.removeEventListener('touchmove', drag)
      window.removeEventListener("mouseup", endDrag)
      window.removeEventListener('touchend', endDrag)
    }
  }, [])

  return(
    <div className={classes.wrapper}>
      <div ref={scrollWrapRef} className={classes.scrollWrap} data-role='art-wall'>
        {/* <div className={classes.layer1}></div> */}
        <img src='https://d1nbo7haysbnoj.cloudfront.net/images/scene-bg.jpg' className={classes.layer1} />
      </div>
    </div>
  )
}

export default Static;