import {useEffect, useRef, useState} from 'react'
import PasswordCover from './PasswordCover';
import Welcome from './components/Screens/Welcome';
import Scene from './components/Scene/Index';
import Map from './components/Scene/Map';
import Location from './components/Screens/Location';
import Instructions from './components/Screens/Instructions';
import Nav from './components/Nav/Nav';
import Static from './components/Scene/Static';
import Cta from './components/Pins/Cta';
import PinForm from './components/Pins/Form';
import SceneBg from './components/Scene/SceneBg';
let canDrag = false;
let dragging = false;
let startX = 0;
let elementX = 0;
let maxX = 0;

let ogImageWidth, ogImageHeight, ogImageRatio, ogImageWidthMobile, ogImageHeightMobile, ogImageRatioMobile;
let firstResize = true;

function App() {
  const scrollWrapRef = useRef();
  const layerWrap = useRef();
  const background = useRef();
  const backgroundMobile = useRef();

  const url = new URL(window.location.href);
  const urlParams = new URLSearchParams(url.search);
  const debug = urlParams.get('debug');
  const debugMode = debug ? true : false;

  const [passwordProtected, setPasswordProtected] = useState(false);
  const [ui, setUI] = useState('welcome');


  const [loading, setLoading] = useState(true);
  const [patchbayLoaded, setPatchbayLoaded] = useState(false);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [userLocationLoaded, setUserLocationLoaded] = useState(false);
  const [audioReady, setAudioReady] = useState(false);

  const [instructionsActive, setInstructionsActive] = useState(false);

  const [locations, setLocations] = useState();
  const [locationsReady, setLocationsReady] = useState(false);
  const [markerActive, setMarkerActive] = useState(false);
  const [activeLocation, setActiveLocation] = useState({});
  const [currentLocation, setCurrentLocation] = useState({lng: -118.2643453, lat: 34.0320466});

  const [pinFormActive, setPinFormActive] = useState(false);



  useEffect(() => {
    if (!passwordProtected) {
      getUserLocation();
      setupAudio();
    }
  },[passwordProtected]);

  const passwordConfirmed = () => {
    setPasswordProtected(false);
  }

  useEffect(() => {
    if (patchbayLoaded && modelsLoaded && userLocationLoaded) {
      setLoading(false);
    }
  }, [patchbayLoaded, modelsLoaded, userLocationLoaded]);

  useEffect(() => {
    if (scrollWrapRef.current && !passwordProtected) {
      window.addEventListener('resize', resize, false);
      
      setTimeout(() => {
          ogImageWidth = background.current.getBoundingClientRect().width;
          ogImageHeight = background.current.getBoundingClientRect().height;
          ogImageRatio = ogImageWidth / ogImageHeight;

          ogImageWidthMobile = backgroundMobile.current.getBoundingClientRect().width;
          ogImageHeightMobile = backgroundMobile.current.getBoundingClientRect().height;
          ogImageRatioMobile = ogImageWidthMobile / ogImageHeightMobile;
          

          resize();
          firstResize = false;
          layerWrap.current.style.width = `${background.current.getBoundingClientRect().width}px`;
      }, 1000);

      function initDrag(e) {
        if (e.target.dataset.role === 'art-wall') {
          canDrag = true
          startX = e.clientX ? e.clientX : e.touches[0].clientX
          elementX = scrollWrapRef.current.getBoundingClientRect().left
          maxX = -scrollWrapRef.current.getBoundingClientRect().width + window.innerWidth
        }
      }
      function drag(e) {
        if (canDrag) {
          scrollWrapRef.current.style.cursor = 'grabbing'
          const currentX = e.touches ? e.touches[0].clientX : e.clientX;
          const distance = currentX - startX
          const newX = distance + elementX

          if (distance < 0) {
            maxX = -scrollWrapRef.current.getBoundingClientRect().width + 400 + window.innerWidth
          }


          if (Math.abs(distance) > 3) {
            dragging = true
            
            if (newX <= -100 && newX >= maxX) {
              scrollWrapRef.current.style.left = `${newX}px`;

              const pinCta = document.querySelector('#pin-cta');
              const mapContainer = document.querySelector('#map-container');
              const mapBounds = mapContainer.getBoundingClientRect();
              pinCta.style.position = 'fixed';
              pinCta.style.bottom = `${window.innerHeight - mapBounds.bottom}px`;
              pinCta.style.left = `${mapBounds.right - 200}px`;
            }
          }
        }
      }
      function endDrag(e) {
        setTimeout(() => {
          dragging = false
        }, 100);
        canDrag = false
        scrollWrapRef.current.style.cursor = 'grab'
      }

      window.addEventListener('mousedown', initDrag)
      window.addEventListener('touchstart', initDrag)
      window.addEventListener('mousemove', drag)
      window.addEventListener('touchmove', drag)
      window.addEventListener('mouseup', endDrag)
      window.addEventListener('touchend', endDrag)
      return () => {
        window.removeEventListener("mousedown", initDrag)
        window.removeEventListener('touchstart', initDrag)
        window.removeEventListener("mousemove", drag)
        window.removeEventListener('touchmove', drag)
        window.removeEventListener("mouseup", endDrag)
        window.removeEventListener('touchend', endDrag)
      }
    }
  }, [scrollWrapRef, passwordProtected])

  const resize = () => {
    let imageWidth, imageHeight, imageRatio;
    if (window.innerWidth < 800) {
      imageWidth = ogImageWidthMobile;
      imageHeight = ogImageHeightMobile;
      imageRatio = ogImageRatioMobile;
      background.current.style.display = 'none';
      backgroundMobile.current.style.display = 'block';
    } else {
      imageWidth = ogImageWidth;
      imageHeight = ogImageHeight;
      imageRatio = ogImageRatio;
      background.current.style.display = 'block';
      backgroundMobile.current.style.display = 'none';
    }

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const windowRatio = windowWidth / windowHeight;

    if (windowRatio > imageRatio) {
      // window is wider than image
      background.current.style.minHeight = '100%';
      background.current.style.height = 'auto';
      background.current.style.minWidth = 'auto';
      background.current.style.width = '100vw';

      backgroundMobile.current.style.minHeight = '100%';
      backgroundMobile.current.style.height = 'auto';
      backgroundMobile.current.style.minWidth = 'auto';
      backgroundMobile.current.style.width = '100vw';
    } else if (windowRatio < imageRatio) {
      // window is taller han image
      background.current.style.minHeight = 'auto';
      background.current.style.height = '100vh';
      background.current.style.minWidth = 'auto';
      background.current.style.width = 'auto';

      backgroundMobile.current.style.minHeight = 'auto';
      backgroundMobile.current.style.height = '100vh';
      backgroundMobile.current.style.minWidth = 'auto';
      backgroundMobile.current.style.width = 'auto';
    }


    if (window.innerWidth < 800) {
      layerWrap.current.style.width = `${backgroundMobile.current.getBoundingClientRect().width}px`;
      layerWrap.current.style.height = `${backgroundMobile.current.getBoundingClientRect().height}px`;
      scrollWrapRef.current.style.width = `${backgroundMobile.current.getBoundingClientRect().width}px`;
      scrollWrapRef.current.style.height = `${backgroundMobile.current.getBoundingClientRect().height}px`;
      if (firstResize) {
        const leftOffset = (backgroundMobile.current.getBoundingClientRect().width / 2) - (window.innerWidth / 2);
        scrollWrapRef.current.style.left = `-${leftOffset}px`;
        firstResize = false;
      }
    } else {
      layerWrap.current.style.width = `${background.current.getBoundingClientRect().width}px`;
      layerWrap.current.style.height = `${background.current.getBoundingClientRect().height}px`;
      scrollWrapRef.current.style.width = `${background.current.getBoundingClientRect().width}px`;
      scrollWrapRef.current.style.height = `${background.current.getBoundingClientRect().height}px`;
      const leftOffset = (background.current.getBoundingClientRect().width / 2) - (window.innerWidth / 2);
      scrollWrapRef.current.style.left = `-${leftOffset}px`;
    }

    const pinCta = document.querySelector('#pin-cta');
    const mapContainer = document.querySelector('#map-container');
    const mapBounds = mapContainer.getBoundingClientRect();
    pinCta.style.position = 'fixed';
    pinCta.style.bottom = `${window.innerHeight - mapBounds.bottom}px`;
    pinCta.style.left = `${mapBounds.right - 200}px`;
  }

  const setupAudio = () => {
    const patchbayScript = document.createElement('script');
    patchbayScript.type = 'module';
    patchbayScript.onload = function () {
      //console.log('IM script')
    };
    patchbayScript.src = "https://js.patchbay.co/v1/embed.js?key=646e0e2101e1a8bc4e928c69";
    document.head.appendChild(patchbayScript);

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(patchbayScript, firstScriptTag);

    document.addEventListener('patchbayReady', () => {
      setPatchbayLoaded(true);
    });
    document.addEventListener('audioOptOut', () => {
      confirmWelcome();
    });
    document.addEventListener('audioReady', () => {
      setAudioReady(true);
    });
  }

  const getLocations = async (lng,lat) => {
    const response = await fetch(`/locations-nearby?lat=${lat ? lat : currentLocation.lat}&lng=${lng ? lng : currentLocation.lng}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (!response.ok) {
      console.log(response)
    }

    const data = await response.json();

    setUserLocationLoaded(true);
    setLocations(data)
    setLocationsReady(true)

    if (lat && lng) {
      setCurrentLocation({lng: lng, lat: lat});
    }
  }

  const getUserLocation = async () => {
    if(!navigator.geolocation) {
      getLocations(currentLocation.lng, currentLocation.lat)
    } else {
      const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })
      .then(position => {
        setCurrentLocation({lat: position.coords.latitude, lng: position.coords.longitude})
        getLocations(position.coords.longitude, position.coords.latitude);
        return true
      })
      .catch(error => {
        console.log(error)
        getLocations(currentLocation.lng, currentLocation.lat)
        return false
      })
        
      return pos
    }
  }

  const updateCurrentLocation = (location) => {
    console.log(location)
    setCurrentLocation({lng: location.lng, lat: location.lat});
  }

  const confirmWelcome = () => {
    setUI('scene')
    setInstructionsActive(true);

    document.dispatchEvent(new CustomEvent('patchPlayAudio'));
  }

  const handleModelsLoaded = () => {
    setModelsLoaded(true);
  }

  const handleMapLoaded = () => {
    //
  }

  const viewMarker = (id) => {
    const location = locations.find(location => location._id === id)
    setActiveLocation(location)
    setMarkerActive(true)
  }

  const closeMarker = () => {
    setMarkerActive(false)
  }

  const closeInstructions = () => {
    setInstructionsActive(false);
  }

  const openPinForm = () => {
    setPinFormActive(true)
  }

  const closePinForm = () => {
    setPinFormActive(false);
  }

  const photoClick = () => {
    window.open('https://a7x.lnk.to/libad', '_blank')
  }

  const recordPlayerClick = () => {
    window.open('https://www.avengedsevenfold.com/libad-vinyl/', '_blank')
  }



  return (
    passwordProtected
      ?
      <PasswordCover passwordConfirmed={passwordConfirmed} />
      :
      <div className='main-wrapper'>
        <div ref={scrollWrapRef} className='scroll-wrapper'>
          <div ref={layerWrap} className='layer-wrap' id='layer-wrap'>
            <img ref={background} src='https://d1nbo7haysbnoj.cloudfront.net/images/bg-desktop_comp.jpg' className='layer1' data-role='art-wall' draggable="false" />
            <img ref={backgroundMobile} src='https://d1nbo7haysbnoj.cloudfront.net/images/a7x-store-bg_mobile_comp.jpg' className='layer1' data-role='art-wall' draggable="false" />
            
            <div id='photo-link' onClick={photoClick}></div>
            <Map
              currentLocation={currentLocation}
              getLocations={getLocations}
              handleMapLoaded={handleMapLoaded}
              locations={locations}
              locationsReady={locationsReady}
              viewMarker={viewMarker}
              debugMode={debugMode}
            />
            <div id='record-player-link' onClick={recordPlayerClick}></div>

            <SceneBg
              handleModelsLoaded={handleModelsLoaded}
              debugMode={debugMode}
            />

            <div id='center-rack'></div>  

            <Cta openForm={openPinForm} />
          </div>
        </div>



        {/* <Scene
          handleModelsLoaded={handleModelsLoaded}
          debugMode={debugMode}
        /> */}
        

        
        
        {instructionsActive && <Instructions closeInstructions={closeInstructions} />}
        
        <Location
          markerActive={markerActive}
          location={activeLocation}
          closeMarker={closeMarker}
        />

        {pinFormActive && <PinForm closeForm={closePinForm} />}
          

        {ui === 'welcome' && (
          <Welcome  
            loading={loading}
            audioReady={audioReady}
            confirmWelcome={confirmWelcome}
          />
        )}

        {ui === 'scene' && <Nav />}

        <div className='footer'>
          <a href='https://www.avengedsevenfold.com/terms-and-conditions' target='_blank' rel='noreferrer'>Terms & Conditions</a>
          <a href='https://www.avengedsevenfold.com/privacy-policy' target='_blank' rel='noreferrer'>Privacy Policy</a>
        </div>

        <div className="patchbay-player" data-id="646e0e6101e1a8bc4e928cf2"></div>
      </div>
  )
}

export default App;
