/* eslint import/no-webpack-loader-syntax: off */
import { useEffect, useRef } from "react";
import classes from './Map.module.css';
import mapboxgl from '!mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import 'mapbox-gl/dist/mapbox-gl.css'; 
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const currentMarkers = [];
function Map(props) {
  const map = useRef();
  const mapContainer = useRef();
  const searchTrigger = useRef();

  useEffect(() => {
    if (!props.debugMode) {
      initMap();
    }
  }, []);

  useEffect(() => {
    if (props.locationsReady && !props.debugMode) {
      map.current.setCenter([props.currentLocation.lng, props.currentLocation.lat]);
    }
  }, [props.locationsReady, props.debugMode])

  useEffect(() => {
    if (props.locations && props.locations.length > 0) {
      addMarkers();
    }
  }, [props.locations])

  const initMap = () => {
    mapboxgl.accessToken = 'pk.eyJ1Ijoid2F5d2FyZC1jcmVhdGl2ZSIsImEiOiJjbDBzbmtpZmQwYThxM2RucWRjd2p6cm0yIn0.aO9j_1cdyvLjxpPQC59RTQ';
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/wayward-creative/cli1zw72r04q001p85blz3w8j',
      center: [props.currentLocation.lng, props.currentLocation.lat],
      zoom: 12,
      minZoom: 7,
      maxZoom: 15
      // trackResize: false
    }); 


    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      flyTo: {
        maxZoom: 12
      }
    })

    map.current.addControl(geocoder);

    searchTrigger.current.addEventListener('click', () => {
      // console.log('searching ')
      searchTrigger.current.dataset.active = 'false';
      props.getLocations(map.current.getCenter().lng,map.current.getCenter().lat);
    })


    let getNewLocations = false;
    geocoder.on('result', (event) => {
      // Automatically search
      console.log(event)
      getNewLocations = true;
      searchTrigger.current.dataset.active = 'false';
    });
    map.current.on('moveend', (e) => {
      if (getNewLocations) {
        // console.log('searching')
        props.getLocations(map.current.getCenter().lng,map.current.getCenter().lat);

        setTimeout(() => {
          getNewLocations = false;
        }, 2000);
      }
    });

    map.current.on('zoomend', (e) => {
      // console.log('zoome end');
      // Dont get new locations
      // getNewLocations = false;
    });

    map.current.on('dragend', (e) => {
      // console.log('drag end');
      // Ask to get new locations
      getNewLocations = false;
      searchTrigger.current.dataset.active = 'true';
    });

    map.current.on('resize', e => {
      // console.log(e)
    })

    props.handleMapLoaded();
  }

  const handleMarkerClick = (id) => {
    props.viewMarker(id);
  }

  const addMarkers = () => {
    if (props.locations) {
      for (const location of props.locations) {
        const el = document.createElement('div');

        if (location.category === 'indie') {
          el.className = 'map-marker map-marker--indie';
        } else if (location.external_id) {
          el.className = 'map-marker map-marker--hot-topic';
        } else if (location.category === 'Newbury Comics') {
          el.className = 'map-marker map-marker--newbury-comics';
        } else if (location.category === 'zia-records') {
          el.className = 'map-marker map-marker--zia';
        } else if (location.category === 'guitar-center' || location.category === 'fye') {
          el.className = 'map-marker map-marker--guitar-center';
        } else if (location.category === 'barnes-noble') {
          el.className = 'map-marker map-marker--barnes-noble';
        } else if (location.category === 'world-exclusive') {
          el.className = 'map-marker map-marker--world-exclusive';
        } else if (location.category === 'standard') {
          el.className = 'map-marker map-marker--standard';
        } else if (location.category === 'germany') {
          el.className = 'map-marker map-marker--germany';
        } else if (location.category === 'orange') {
          el.className = 'map-marker map-marker--orange';
        } else if (location.category === 'australia') {
          el.className = 'map-marker map-marker--germany';
        } else {
          el.className = 'map-marker map-marker--standard';
        }
        
        el.addEventListener('click', () => {
          handleMarkerClick(location._id)
        });
        
        // Add markers to the map.
        const marker = new mapboxgl.Marker(el)
        .setLngLat([location.lng, location.lat])
        .addTo(map.current);

        currentMarkers.push(marker)
      }
    }
  }

  const handleTest = () => {
    mapContainer.current.style.backgroundColor = 'green'
  }

  return(
    <div ref={mapContainer} id='map-container' className={classes.container} data-debug={props.debugMode}>
      {props.debugMode && <button onClick={handleTest} className={classes.test}>test</button>}
      
      {!props.debugMode && (
        <>
          <div id='map-loading' className={classes.mapLoading} data-status='hidden'>Loading locations...</div>
          <div id='map-search-trigger' ref={searchTrigger} className={classes.searchTrigger}>Search this area</div>
        </>
      )}
      
    </div>
  )
}

export default Map;