import { useState } from 'react';
import IconMapMarker from '../Icons/IconMapMarker';
import MenuPanel from './MenuPanel';
import classes from './Nav.module.css';
import LogoA7x from '../Ui/Logos/LogoA7x';

function Nav() {
  const [menuOpen, setMenuOpen] = useState(false);

  const togglePanel = () => {
    if (menuOpen) {
      setMenuOpen(false)
    } else {
      setMenuOpen(true);
    }
  }

  return(
    <>
      <MenuPanel active={menuOpen} />

      <div className={classes.title}>
        <img src='https://d1nbo7haysbnoj.cloudfront.net/images/A7X_LifeIsButaDream-text.png' />
        <div>VINYL LOCATOR</div>
      </div>

      <button className={classes.navBtn} data-role='menu' data-active={menuOpen} onClick={togglePanel}>{menuOpen ? 'X CLOSE' : 'Menu'} </button>

      
    </>
  )
}

export default Nav;