import classes from './LoadingBar.module.css'

function Loading(props) {
  return(
    <div className={classes.bar}>
      <div className={classes.text}>
        EXPERIENCE LOADING...
      </div>
    </div>
  )
}

export default Loading;