import { useEffect, useState } from 'react';
import Modal from '../Ui/Modal';
import classes from './Pins.module.css';
import Sharing from '../../components/Social/Sharing';


function PinForm(props) {
  const [name, setName] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [location, setLocation] = useState();
  const [pinCreated, setPinCreated] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState()
  const [results, setResults] = useState();
  const [autocompleteActive, setAutocompleteActive] = useState(false)
  const [autocompleteValue, setAutocompleteValue] = useState()

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (!e.target.name && e.target.name !== 'store') {
        const autoComplete = document.querySelector('.autocomplete')
        if (autoComplete && autoComplete.dataset.active === 'true' && !e.target.classList.contains('autocomplete')) {
          setAutocompleteActive(false)
        }
      }
    })
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!selectedFile) {
      setError('Please add a photo')
    } else if (!location) {
      setError('Please select a location')
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("image", selectedFile);
      formData.append("location", location);
      
      setFormSubmitted(true)

      
      // RECAPTCHA
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute("6LcEWTgmAAAAADkoJWsicWgBlFitNYDjtxMzJYC0", {action: 'submit'}).then(token => {
          formData.append("token", token);

          fetch('/upload', {
            method: 'POST',
            body: formData
          })
          .then(response => response.json())
          .then(data => {
            if (data.error) {
              if (data.subcode && data.subcode === 100) {
                // Invalid recaptcha
                setPinCreated(true)
              } else {
                setFormSubmitted(false)
                if (data.error === 'File too large') {
                  setError('File is too large. Max file size is 5MB')
                } else {
                  setError(data.error)
                }
              }

              return
            }

            setPinCreated(true)
          })
          .catch(error => {
            console.log(error)
            setFormSubmitted(false)
          });
        });
      });
    }
  }

  const autocomplete = (e) => {
    const query = e.target.value;

    setAutocompleteValue()
    fetch(`/locations?q=${query}`, {
      method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
      setResults(data)
    })
    .catch(error => console.log(error))
  }

  const activateAutocomplete = () => {
    setAutocompleteActive(true)
  }

  const selectResult = (e) => {
    const location_id = e.target.dataset.locationid;
    setLocation(location_id)
    setAutocompleteValue(e.target.textContent)
    setResults()
    setAutocompleteActive(false)
  }

  return(
    <Modal
      active={true}
      allowClose={true}
      close={props.closeForm}
      size='med'
    >
        {pinCreated
          ? 
          <div className={classes.pinConfirmation}>
            <h2>PIN ADDED!</h2>
            <hr/>
            <p>
              Once approved, you'll see your picture under the record store you shopped at!
            </p>
            <Sharing />
          </div>
          : 
          <>
            <form onSubmit={handleSubmit} className={classes.form} data-submitted={formSubmitted}>
              <h2>Add your pin</h2>
              
              <p>
                Add your "Life is But a Dream..." experience to the map! Submit a photo at the location where you purchased your vinyl.
              </p>

              <div className='flex-between'>
                <div className='input-row flex-div--48'>
                  <input
                    type='text'
                    name='name'
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Your name...'
                  />
                </div>

                <div className='input-row flex-div--48'>
                  <label htmlFor='file-upload' className="custom-file-upload">
                    <input 
                      id='file-upload'
                      type='file'
                      name='image'
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                      placeholder='Add an image'
                    />
                    {selectedFile ? 'PHOTO ADDED' : 'UPLOAD A PHOTO'}
                  </label>
                </div>
              </div>

              <p>
                THEN PICK YOUR STORE:
              </p>

                <div className='input-row'>
                  <div className='autocomplete' data-active={autocompleteActive}>
                    <input
                      onFocus={activateAutocomplete}
                      type='text'
                      name='store'
                      value={autocompleteValue}
                      onChange={autocomplete}
                      placeholder='Search by store name'
                      autoComplete="off"
                    />
                    <div className='autocomplete__results'>
                      {results
                        ?
                        results.map((result, i) => (
                          <div key={`result_${i}`} onClick={selectResult} className='autocomplete-result' data-locationid={result._id}>
                            {result.name} - <span className='autocomplete-result__subtitle'>{result.city}, {result.state}</span>
                          </div>
                        ))
                        : null
                      }
                    </div>
                  </div>
                </div>

              <p>
                {error}
              </p>
              <div id='recaptcha-container'></div>   
              <div className='input-row'>
                <button className='btn btn--primary btn--block btn--paint btn--lg'>Add Pin</button>
              </div>
            </form>
            <div className={classes.formStatus} data-submitted={formSubmitted}>
              Adding pin...
            </div>
          </>
        }
    </Modal>
  )
}

export default PinForm;